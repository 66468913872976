import { ArrowRightOutlined } from '@ant-design/icons';
import React, { startTransition, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { footerContent } from '../../constants';
import Button from '../Button/Button';
import Input from '../Input/Input';
import styles from './Footer.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubscribe = (e) => {
    e.preventDefault();

    if (isValidEmail(email)) {
      setError(false);
      setSubmitting(true);

      setTimeout(() => {
        setSubmitting(false);
        setEmail('');
      }, 3000);
    } else {
      setError('Please enter a valid email');
    }
  };

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    startTransition(() => {
      navigate(path);
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    });
  };

  const FoooterSection = ({ item }) => {
    return (
      <div className={styles.link_side}>
        <h2 className={styles.footer_header}>{item.header}</h2>
        <ul>
          {item?.subTabs.map((link, index) => {
            return (
              <li key={index} onClick={() => (link.link ? handleNavigation(link.link) : null)}>
                {link.icon && <div className={styles.front_link_icon}>{link.icon}</div>}
                {link.label}
                {link.isExternalLink && (
                  <div className={styles.link_icon}>
                    <ArrowRightOutlined />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <footer className={styles.Footer}>
      <div className={styles.container}>
        <div className={styles.logo_side}>
          <div className={styles.logo} />
          <span>Smart Billing Platform for Medical Providers</span>
        </div>
        {footerContent.map((item, index) => {
          return <FoooterSection key={index} item={item} />;
        })}
        <div className={styles.form_side}>
          <h2 className={styles.footer_header}>Subscribe</h2>

          <form>
            <Input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              hasLabel={false}
              label="Email"
              helperText={error}
            />
            <Button
              variant={email ? 'secondary' : 'disabled'}
              label="Subscribe"
              onClick={handleSubscribe}
              loading={submitting}
            />
          </form>
        </div>
      </div>
      <div className={styles.footer_owner}>
        <span>© {year} - Anise Technologies Inc.</span>
      </div>
    </footer>
  );
};

export default Footer;
