import React from 'react';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import styles from '../components/Hero/Hero.module.scss';
import Form from '../components/Form/Form';

const Demo = () => {
  return (
    <ContentWrapper>
      <section className={styles.Hero}>
        <div className={styles.content}>
          <span className={styles.labelHeader}>Request demo</span>
          <h1 className={styles.heroHeader}>Talk to Sales</h1>
        </div>
        <Form />
      </section>
    </ContentWrapper>
  );
};

export default Demo;
