import React, { startTransition, useState } from 'react';
import {
  ArrowRightOutlined,
  CloseOutlined,
  DockerOutlined,
  DownOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import styles from './Navbar.module.scss';
import Button from '../Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import { navigation } from '../../constants';
import { handleExternalNavigation } from '../../utils';

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    startTransition(() => {
      navigate(path);
    });
  };

  const MenuItem = ({ tab }) => {
    return (
      <div className={styles.item}>
        <div
          className={styles.item_header}
          onClick={() => {
            handleNavigation(tab.baseLink);
            setShowMobileMenu(false);
          }}>
          <span>{tab.header}</span>
          {tab.tabs && <DownOutlined />}
        </div>

        <div className={styles.item_body}>
          {tab.tabs?.map((dd_tab) => {
            return (
              <div
                onClick={() => {
                  handleNavigation(tab.baseLink + dd_tab.linkTo);
                  setShowMobileMenu(false);
                }}
                key={JSON.stringify(dd_tab)}
                className={styles.dropdown_item}>
                <div className={styles.dropdown_item_icon}>
                  <DockerOutlined />
                </div>
                <span className={styles.span}>{dd_tab.label}</span>
                <ArrowRightOutlined />
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.Navbar}>
      <nav>
        <Link to="/">
          <div className={styles.logo} />
        </Link>
        <div className={styles.links}>
          <div className={styles.item_row}>
            {navigation.map((tab) => (
              <MenuItem tab={tab} key={JSON.stringify(tab)} />
            ))}
          </div>
          <div className={styles.login_button}>
            <Button
              label="Login"
              onClick={() => handleExternalNavigation('https://app.anise.health')}
              variant="secondary"
            />
            <Button
              label="Book a Demo"
              onClick={() => {
                setShowMobileMenu(false);
                handleNavigation('/request-demo');
              }}
            />
          </div>
        </div>
        <div className={styles.mobile_tab} onClick={() => setShowMobileMenu(!showMobileMenu)}>
          {!showMobileMenu ? <MenuOutlined /> : <CloseOutlined />}
        </div>
      </nav>
      {showMobileMenu && (
        <div className={`${styles.mobile_menu}`}>
          <div className={styles.mobile_menu_items}>
            {navigation.map((tab) => (
              <MenuItem tab={tab} key={JSON.stringify(tab)} />
            ))}
          </div>
          <div className={styles.login_button}>
            <Button
              label="Login"
              onClick={() => handleExternalNavigation('https://app.anise.health')}
              variant="secondary"
            />
            <Button label="Book a Demo" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
