import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Describer from '../components/Describer/Describer';
import Hero from '../components/Hero/Hero';
import { eligibilityContent, navigation } from '../constants';

const Eligibility = () => {
  const page = navigation.filter((content) => content.header === 'Eligibility')[0];

  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const elementId = hash.replace('#', '');
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <>
      <Hero
        type={'eligibility'}
        header={'Verify And Track Your Census Eligibility'}
        subHeader={
          'Unlimited eligibility and benefits verification checks to maximize your payouts'
        }
        hasBtn={false}
      />
      {eligibilityContent.map((section, index) => {
        return (
          <Describer
            key={index}
            header={section.header}
            hasBGPatterns={index % 2 === 1}
            description={section.subHeader}
            hasBtn={false}
            type={index % 2 !== 0 ? 'RIGHT' : 'LEFT'}
            id={section.type}
            image={section.image}
          />
        );
      })}
    </>
  );
};

export default Eligibility;
