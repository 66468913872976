import React from 'react';
import Button from '../Button/Button';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import styles from './Hero.module.scss';

const Hero = ({ type, header, subHeader, hasBtn, btnLabel, handleButtonClick, id }) => {
  const illustrations = {
    homepage: (
      <div className={styles.homeIllustration}>
        <div className={styles.webImage}></div>
        <div className={styles.mobileImage}></div>
      </div>
    ),
    billing: (
      <div className={styles.otherIllustration}>
        <div className={styles.billingImage} />
      </div>
    ),
    eligibility: (
      <div className={styles.otherIllustration}>
        <div className={styles.eligibilityProgress}></div>
        <div className={styles.eligibilityImage} />
      </div>
    ),
    platform: (
      <div className={styles.otherIllustration}>
        <div className={styles.platformImage} />
      </div>
    ),
  };

  return (
    <ContentWrapper id={id}>
      <section className={styles.Hero}>
        <div className={styles.content}>
          {type && <span className={styles.labelHeader}>{type}</span>}
          <h1 className={styles.heroHeader}>{header}</h1>
          <p className={styles.heroSubtext}>{subHeader}</p>
          {hasBtn && <Button label={btnLabel} onClick={handleButtonClick} />}
        </div>
        {illustrations[type]}
      </section>
    </ContentWrapper>
  );
};

export default Hero;
