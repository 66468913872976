import React, { useState, useEffect } from 'react';
import Button from '../Button/Button';
import Input from '../Input/Input';
import styles from './Form.module.scss';

const formFields = [
  { name: 'name', label: 'Name', type: 'text', required: true },
  { name: 'company', label: 'Company', type: 'text', required: true },
  { name: 'email', label: 'Email Address', type: 'email', required: true },
  { name: 'phone', label: 'Phone Number', type: 'text', required: true },
  { name: 'message', label: 'Message', type: 'text', required: true },
];

const Form = () => {
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isValid = formFields.every(
      (field) => !field.required || (field.required && formData[field.name])
    );
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form className={styles.Form}>
      {formFields.map((field) => (
        <Input
          key={field.name}
          name={field.name}
          label={field.label}
          type={field.type}
          required={field.required}
          value={formData[field.name] || ''}
          onChange={(e) => handleChange(field.name, e.target.value)}
        />
      ))}
      <div className={styles.Button}>
        <Button
          label="Submit"
          disabled={!isFormValid}
          onClick={!isFormValid ? null : handleSubmit}
          variant={!isFormValid ? `disabled` : `default`}
        />
      </div>
    </form>
  );
};

export default Form;
