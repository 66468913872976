// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_Form__DMpDX {
  width: 80%;
  padding: 20px;
  background-color: #ffffff;
  margin: 10px;
  border: 1px solid #e3ebf2;
  border-radius: 8px;
  margin-right: 50px;
}
.Form_Form__DMpDX .Form_Button__RAR55 {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
@media (max-width: 768px) {
  .Form_Form__DMpDX {
    margin: 0;
    margin-bottom: 15px;
    border-radius: 20px;
  }
  .Form_Form__DMpDX .Form_Button__RAR55 {
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Form/Form.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EACA,aAAA;EACA,yBCCY;EDAZ,YAAA;EACA,yBAAA;EACA,kBCIc;EDHd,kBAAA;AADF;AAGE;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;AADJ;AAIE;EAfF;IAgBI,SAAA;IACA,mBAAA;IACA,mBAAA;EADF;EAGE;IACE,uBAAA;EADJ;AACF","sourcesContent":["@import '../../variables';\n\n.Form {\n  width: 80%;\n  padding: 20px;\n  background-color: $white-color;\n  margin: 10px;\n  border: 1px solid $card-border-color;\n  border-radius: $border-radius;\n  margin-right: 50px;\n\n  .Button {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 30px;\n  }\n\n  @media (max-width: 768px) {\n    margin: 0;\n    margin-bottom: 15px;\n    border-radius: 20px;\n\n    .Button {\n      justify-content: center;\n    }\n  }\n}\n","// _variables.scss\n\n$primary-color: #ffbc51;\n$primary-dark-color: #d99221;\n$secondary-color: #015d8e;\n$secondary-dark-color: #0b4b6d;\n$white-color: #ffffff;\n$black-color: #212121;\n$black-dark-color: #000000;\n$card-border-color: #e3ebf2;\n$input-background-color: #fafafa;\n\n$border-radius: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": `Form_Form__DMpDX`,
	"Button": `Form_Button__RAR55`
};
export default ___CSS_LOADER_EXPORT___;
