import React from 'react';
import styles from './Input.module.scss';

const Input = ({ hasLabel = true, label, onChange, required = false, type, value, helperText }) => {
  return (
    <div className={styles.Input}>
      {hasLabel && (
        <label htmlFor="">
          {label} <span style={{ color: 'red' }}>{required && '*'}</span>
        </label>
      )}

      <div className={styles.InputField}>
        <input
          onChange={onChange}
          value={value}
          type={type}
          placeholder={label}
          required={required}></input>

        {helperText && <span className={styles.error}>{helperText}</span>}
      </div>
    </div>
  );
};

export default Input;
