// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_Input__aCYn4 {
  width: 100%;
  display: flex;
  align-items: center;
}
.Input_Input__aCYn4 label {
  width: 50%;
  font-size: 14px;
}
.Input_Input__aCYn4 .Input_InputField__cCDt5 {
  width: 100%;
  margin: 8px 0;
}
.Input_Input__aCYn4 .Input_InputField__cCDt5 > span {
  font-size: 11px;
  margin-top: 0;
}
.Input_Input__aCYn4 .Input_InputField__cCDt5 > span.Input_error__1wKFK {
  color: "red";
}
.Input_Input__aCYn4 input[type=text],
.Input_Input__aCYn4 input[type=email] {
  font-size: 14px;
  width: 100%;
  background-color: #fafafa;
  border: none;
  border: 1.3px solid #e3ebf2;
  color: #212121;
  font-size: 16px;
  outline: none;
  padding: 8px;
  height: 45px;
  box-sizing: border-box;
  transition: 0.3s;
  border-radius: 8px;
}
.Input_Input__aCYn4 input[type=text]:focus {
  border: 1.3px solid rgb(169, 176, 207);
}
@media (max-width: 768px) {
  .Input_Input__aCYn4 {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .Input_Input__aCYn4 label {
    text-align: left;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Input/Input.module.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AADF;AAGE;EACE,UAAA;EACA,eAAA;AADJ;AAIE;EACE,WAAA;EACA,aAAA;AAFJ;AAII;EACE,eAAA;EACA,aAAA;AAFN;AAIM;EACE,YAAA;AAFR;AAOE;;EAEE,eAAA;EACA,WAAA;EACA,yBCpBqB;EDqBrB,YAAA;EACA,2BAAA;EACA,cC1BU;ED2BV,eAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBC5BY;ADuBhB;AAQE;EACE,sCAAA;AANJ;AASE;EA7CF;IA8CI,sBAAA;IACA,uBAAA;IACA,mBAAA;EANF;EAQE;IACE,gBAAA;EANJ;AACF","sourcesContent":["@import '../../variables';\n\n.Input {\n  width: 100%;\n  display: flex;\n  align-items: center;\n\n  label {\n    width: 50%;\n    font-size: 14px;\n  }\n\n  .InputField {\n    width: 100%;\n    margin: 8px 0;\n\n    > span {\n      font-size: 11px;\n      margin-top: 0;\n\n      &.error {\n        color: 'red';\n      }\n    }\n  }\n\n  input[type='text'],\n  input[type='email'] {\n    font-size: 14px;\n    width: 100%;\n    background-color: $input-background-color;\n    border: none;\n    border: 1.3px solid $card-border-color;\n    color: $black-color;\n    font-size: 16px;\n    outline: none;\n    padding: 8px;\n    height: 45px;\n    box-sizing: border-box;\n    transition: 0.3s;\n    border-radius: $border-radius;\n  }\n\n  input[type='text']:focus {\n    border: 1.3px solid rgb(169, 176, 207);\n  }\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    align-items: flex-start;\n    margin-bottom: 20px;\n\n    label {\n      text-align: left;\n    }\n  }\n}\n","// _variables.scss\n\n$primary-color: #ffbc51;\n$primary-dark-color: #d99221;\n$secondary-color: #015d8e;\n$secondary-dark-color: #0b4b6d;\n$white-color: #ffffff;\n$black-color: #212121;\n$black-dark-color: #000000;\n$card-border-color: #e3ebf2;\n$input-background-color: #fafafa;\n\n$border-radius: 8px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": `Input_Input__aCYn4`,
	"InputField": `Input_InputField__cCDt5`,
	"error": `Input_error__1wKFK`
};
export default ___CSS_LOADER_EXPORT___;
