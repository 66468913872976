import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './Button.module.scss';

const Button = ({ label = 'Label', onClick, variant = 'default', loading = false }) => {
  const buttonClass = `${styles.Button} ${styles[variant]}`;

  return (
    <button className={buttonClass} onClick={!loading ? onClick : null}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          <span>{label}</span>
          <div className={styles.icon}>
            <ArrowRightOutlined />
          </div>
        </>
      )}
    </button>
  );
};

export default Button;
