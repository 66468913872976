import { QuestionOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './Feature.module.scss';
import visibilityAnimation from '../../assets/gifs/wired-outline-69-eye.gif';
import animation2 from '../../assets/gifs/wired-outline-966-privacy-policy.gif';
import avatarMan from '../../assets/gifs/wired-outline-268-avatar-man.gif';
import settings from '../../assets/gifs/wired-outline-1330-rest-api.gif';

const Feature = ({ header, description, hasBtn, btnLabel, btnLink, icon }) => {
  return (
    <div className={styles.Feature}>
      <img
        src={
          icon === 'visibility-icon'
            ? visibilityAnimation
            : icon === 'automation-icon'
            ? settings
            : icon === 'compliant-icon'
            ? animation2
            : icon === 'full-service'
            ? avatarMan
            : null
        }
        alt=""
        height={50}
      />
      {/* {icon} */}
      <h3>{header}</h3>
      <p>{description}</p>
      {hasBtn && (
        <a href="#">
          {btnLabel} <RightOutlined />
        </a>
      )}
    </div>
  );
};

export default Feature;
